<template>
  <v-layout v-if="data" wrap>
    <v-flex xs8 
      v-if="data.config.External"
    />
    <v-flex xs8 v-else style="padding: 20px;">
      <center v-if="data.areas">
        {{ data.config.TitleArea }}
      </center>
      <center v-if="data.areas">
        <v-btn-toggle v-model="area">
          <v-btn
            v-for="(item, index) in data.areas"
            :key="index"
          >
            {{ item.Name }}
          </v-btn>
        </v-btn-toggle>
      </center>
      <v-btn
        @click="handleNext"
        color="primary"
        block
        style="margin-top: 20px"
        x-large
      >
        {{ $t('common.next') }}
      </v-btn>
    </v-flex>
    <v-flex xs4>
      <table class="list">
        <tr
          class="title" 
          :style="{ 'background-color': color }"
        >
          <td>
            {{ data.config.TitleNumber }}
          </td>
          <td>
            {{ data.config.TitleArea }}
          </td>
          <td class="actions" />
        </tr>
        <tr class="item"
          v-for="(item, index) of data.data"
          :key="index"
          :style="{ 'background-color': color.concat(index % 2 === 0 ? '4D' : '33') }"
        >
          <td class="value">
            <b>
              {{ item.Number }}
            </b>
          </td>
          <td class="value">
            <b>
              {{ item.Area }}
            </b>
          </td>
          <td class="actions">
            <!--v-btn
              v-if="data.areas[area].Name === item.Area"
              text
            >
              <v-icon>mdi-reload-alert</v-icon>
            </v-btn-->
            <v-icon v-if="data.areas[area].Name === item.Area">mdi-reload-alert</v-icon>
          </td>
        </tr>
      </table>
    </v-flex>
  </v-layout>
</template>
<script>
  //import io from 'socket.io-client'
  //import { io } from 'socket.io-client'
  import utils from '@/services/utils'
  import api from '@/services/api'
  export default {
    data () {
      return {
        socket: null,
        data: null,
        area: 0,
        actualNumber: 0,
        color: '#33d1ff', // 4D, 33
      }
    },
    computed: {
      id () {
        return  utils.getWorkspace('queue')
      },
      isDev () {
        return process.env.VUE_APP_ENV === 'development' 
      },
    },
    sockets: {
      connect () {
        console.log('socket connected')
      },
      updateList (v)  {
        console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)', v)
        if (v.id === this.id) this.handleGetData()
      },
    },
    created () {
      this.initWS()
    },
    beforeDestroy () {
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      initWS () {
        this.$socket.connect()
        /*
        // this.socket = io('http://'+process.env.VUE_APP_API_URL)
        // this.socket = io.connect(this.isDev ? `http://localhost:3200` : 'https://cm-dviewcontent-api.dviewcontent.com', { withCredentials: false })
        //this.socket = io(this.isDev ? `http://localhost:3200` : 'https://cm-dviewcontent-api.dviewcontent.com', { withCredentials: false })
        this.socket = io(this.isDev ? `http://localhost:3200` : 'https://cm-dviewcontent-api.dviewcontent.com', { withCredentials: false })
        this.socket.on('connect', () => {
          console.log('connection')
        })
        this.socket.on('updateList', (v) => {
          if (v.id === this.id) this.handleGetData()
        })
        */
      },
      handleGetData () {
        api.getItem ('queue', 'private/queues/', this.id)
          .then(response => {
            if (response.config.PrimaryColor) this.color = response.config.PrimaryColor.substring(0, 7)
            this.data = response
            this.actualNumber = this.data.data.length > 0 ? this.data.data[0].Number : 0
          })
      },
      handleNext() {  
        api.updateItem ('queue', 'private/queues/', this.id, { area: this.data.areas && this.data.areas.length > 0 ? this.data.areas[this.area].Name : '' })
          /*.then(response => {
            console.log(response)
          })*/
      },
    },
  }
</script>
<style scoped>
  .actual {
    font-size: 60px;
  }
  .actual .value {
    font-size: 150px;
    padding: 0 20px;
  }
  .list {
    width: 100%;
    cell-spacing: 5px;
    color: white;
  }
  .list tr {
    height: 130px;
    text-align: center;
  }
  .list td {
    width: 50%;
    font-size: 100px;
    line-height: 1;
  }
  .list .title {
    background-color: #237C63;
    height: 100px !important;
  }
  .list .title td {
    font-size: 40px !important;
  }
  .list .value {
    color: #144938;
    font-size: 30px !important;
  }
  .item {
    height: 40px !important;
  }
  .actions {
    width: auto !important;
    background-color: #ccc !important;
    height: 40px !important;
  }
.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>

